import React from 'react';
import ReactMapGL, { Source, Layer, WebMercatorViewport, LinearInterpolator  } from 'react-map-gl';
import bbox from '@turf/bbox';
import './Map.css';
import IHoverInfo from 'types/hoverinfo';
import  { distanceBetweenPoints } from 'utils/helpers';
import useComponentSize from '@rehooks/component-size'
import dotenv from 'dotenv'
dotenv.config()

interface IMap {
    setClicked(feature: GeoJSON.Feature | null): void
}

interface IViewport {
    latitude: number;
    longitude: number;
    zoom: number;
    transitionInterpolator?: any;
    transitionDuration?: number;
}

const MAPBOX_TOKEN = 'pk.eyJ1Ijoid2Fud2VpbGl1IiwiYSI6ImNrcTRvYjFnZzBpajkydm9oMmUwNXdyN3MifQ.TYlRPulE_-oJRmMobSWVkQ';
const initialState = {
    viewport: {
        latitude: 43.6534817,
        longitude: -79.3839347,
        zoom: 11,
    },
};

const MAX_BOUNDS = [[43.58385571318672, -79.55237449554033],[43.85937701867618, -79.16770725190736]];
const BOUNDS_CENTER = [(MAX_BOUNDS[0][0]+MAX_BOUNDS[1][0])/2, (MAX_BOUNDS[0][1]+MAX_BOUNDS[1][1])/2];
const BOUNDS_RADIUS = distanceBetweenPoints(MAX_BOUNDS[1], MAX_BOUNDS[0]) / 2.6;
const MAX_ZOOM = 9

const Map: React.FunctionComponent<IMap> =  props => {
    const [viewport, setViewport] = React.useState<IViewport>(initialState.viewport);
    const [geoJson, setGeoJson] = React.useState<GeoJSON.FeatureCollection | null>(null);
    const [hoverInfo, setHoverInfo] = React.useState<IHoverInfo | null>(null);
    const contentRef = React.useRef(null);
    const { width: contentWidth, height: contentHeight } = useComponentSize(contentRef);
  
    React.useEffect(() => {
        fetch(
            'https://rdittrich97.github.io/data/TorontoNeighbourhoods.geojson'
        )
            .then(resp => resp.json())
            .then(json => setGeoJson(json as GeoJSON.FeatureCollection))
    }, []);
    const onHover = React.useCallback(event => {
        if (event.target.className !== 'overlays' || event.changedPointers !== undefined) {
            setHoverInfo(null)
            return;
        }
        const {features , srcEvent: {offsetX, offsetY}} = event;
        const hoveredFeature: GeoJSON.Feature = features && features[0];
        setHoverInfo(hoveredFeature ? {
            feature: hoveredFeature,
            x: offsetX,
            y: offsetY
        } : null
        );
    }, []);

    const onClick = (event:any) => {
        const clickedFeature: GeoJSON.Feature = event.features[0];
        if (clickedFeature && Object.keys(clickedFeature.properties as Object).length !== 0) {
            props.setClicked(clickedFeature);
            setHoverInfo(null)
            const [minLng, minLat, maxLng, maxLat] = bbox(clickedFeature);
            const vp = new WebMercatorViewport({...viewport, width: contentWidth, height: contentHeight} );
            const {longitude, latitude, zoom} = vp.fitBounds(
                [
                    [minLng, minLat],
                    [maxLng, maxLat]
                ],
                {
                    padding: 40
                }
            );
            onViewportChange({
                ...viewport,
                longitude,
                latitude,
                zoom,
                transitionInterpolator: new LinearInterpolator({
                around: [event.offsetCenter.x, event.offsetCenter.y]
            }),
                transitionDuration: 1000
            });
        } else {
            props.setClicked(null);
        }
    };

    function onViewportChange(viewport: IViewport) {
       
        const distance = distanceBetweenPoints([viewport.latitude, viewport.longitude], BOUNDS_CENTER)
        var zoom = viewport.zoom;
        if (viewport.zoom < MAX_ZOOM) {
            zoom = MAX_ZOOM
        }
    
        if (distance > BOUNDS_RADIUS) {
            var fromOrigin2Viewport = [viewport.latitude - BOUNDS_CENTER[0], viewport.longitude - BOUNDS_CENTER[1]]
            fromOrigin2Viewport[0] *= BOUNDS_RADIUS / distance
            fromOrigin2Viewport[1] *= BOUNDS_RADIUS / distance
    
            const adjustedViewport = [BOUNDS_CENTER[0]+ fromOrigin2Viewport[0], BOUNDS_CENTER[1]+ fromOrigin2Viewport[1]]
            setViewport({...viewport, latitude: adjustedViewport[0], longitude: adjustedViewport[1], zoom: zoom})
    
        } else {
            setViewport({...viewport, zoom:zoom})
        }
       
    }   

    return (
        <div className="map" ref={contentRef}>
            <ReactMapGL
                {...viewport}
                mapboxApiAccessToken={MAPBOX_TOKEN}
                onViewportChange={(v: IViewport) => onViewportChange(v)}
                /*onHover={onHover}*/
                onClick={onClick}
                height='100%'
                width='100%'
            >   
                {geoJson &&
                    <div>
                    <Source id='geodata' type='geojson' data={geoJson}>
                        <Layer type='fill' paint={{'fill-color': '#00ffff','fill-opacity': 0.1, 'fill-outline-color': '#000000'}} />
                        <Layer type='line' paint={{'line-width': 1.5,'line-opacity': 0.1, 'line-color': '#000000'}} />
                        
                    </Source> 
                    {/*{hoverInfo && (<NeighborhoodTooltip {...hoverInfo}/>)}*/}
                    </div>   
                }
                {/*<Neighborhood feature={clickInfo} isOpen={isPanelOpen} closePanel={closePanel}/>*/}

            </ReactMapGL>
        </div>
    );
}

export default Map;