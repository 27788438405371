import Papa from 'papaparse';
import INeighborhoodProfile from 'types/neighborhoodprofile';

class NeighborhoodProfiles {
    private static neighborhoodProfiles: Array<INeighborhoodProfile> = [];

    private static async getData() {
        const response = await fetch('https://rdittrich97.github.io/data/neighbourhood-profiles-2016-csv.csv').then(response => response.text());
        const results = Papa.parse(response, {header:true});
        const rows = results.data;
        this.neighborhoodProfiles = rows as Array<INeighborhoodProfile>;
    }

    public static async getProfiles() {
        if (!NeighborhoodProfiles.neighborhoodProfiles.length) {
            await NeighborhoodProfiles.getData()
        }
        return NeighborhoodProfiles.neighborhoodProfiles;
    }

}

export default NeighborhoodProfiles;