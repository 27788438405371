import React, { useEffect, useState } from 'react';
import NeighborhoodProfiles from 'controllers/NeighborhoodProfiles';
import INeighborhoodProfile from 'types/neighborhoodprofile';
import { calculatePercent, sanitizeAreaName } from 'utils/helpers';
import { Typography } from '@material-ui/core';
interface IHumanStructure{
    feature: GeoJSON.Feature | null,
}

const HumanStructure: React.FunctionComponent<IHumanStructure> =  props => {
    const [data, setData] = useState<Array<{label:string, value: number | string}>>([]);
    const [sanitizedName, setSanitizedName] = useState('');
    var areaName:string = 'City of Toronto'
    if (props.feature?.properties?.AREA_NAME) {
        areaName = props.feature.properties.AREA_NAME
    }
    useEffect(() => {
        try {
            if (areaName === undefined) {
                return;
            }
            setSanitizedName(sanitizeAreaName(areaName));
            NeighborhoodProfiles.getProfiles().then((profiles: Array<INeighborhoodProfile>) => {
                const nKey = sanitizedName as keyof INeighborhoodProfile;
                const total = parseInt(profiles[1336][nKey] as string)
                const newData = [];
                const enthicMajority = calculatePercent(parseInt(profiles[1336][nKey] as string), parseInt(profiles[1348][nKey] as string));
                for(let i = 1336; i < 1349; i++ ) {
                    newData.push({label:profiles[i]['Characteristic'].trim(), value: profiles[i][nKey]})
                }
                /*newData.push({
                    label: "Ethnic Majority", 
                    value: (enthicMajority * 100).toFixed(2)+"%"
                })*/
                setData(newData)
            })
        } catch(err) {
            console.log(err);
        }
    }, [areaName, sanitizedName])

   
    return (
        <div>
            <div>Name: {areaName}</div>
            {data.map(d => {
                return <p key={d.label}>{d.label}: {d.value}</p>
            })}
        </div>)
}
export default HumanStructure;