import React, { useEffect, useState } from 'react';
import NeighborhoodProfiles from 'controllers/NeighborhoodProfiles';
import INeighborhoodProfile from 'types/neighborhoodprofile';
import { calculatePercent, sanitizeAreaName } from 'utils/helpers';

interface INeighborhood{
    feature: GeoJSON.Feature | null,
}

const Neighborhood: React.FunctionComponent<INeighborhood> =  props => {

    const [data, setData] = useState<Array<{label:string, value: number | string}>>([]);
    const [sanitizedName, setSanitizedName] = useState('');
    var areaName:string = 'City of Toronto'
    if (props.feature?.properties?.AREA_NAME) {
        areaName = props.feature.properties.AREA_NAME
    }
    useEffect(() => {
        try {
            if (areaName === undefined) {
                return;
            }
            setSanitizedName(sanitizeAreaName(areaName));
            NeighborhoodProfiles.getProfiles().then((profiles: Array<INeighborhoodProfile>) => {
                const nKey = sanitizedName as keyof INeighborhoodProfile;
                console.log(nKey)
                const enthicMajority = calculatePercent(parseInt(profiles[1336][nKey] as string), parseInt(profiles[1348][nKey] as string));
                setData([
                    {
                        label: "Ethnic Majority", 
                        value: (enthicMajority * 100).toFixed(2)+"%"
                    }, {
                        label: "Median Income",
                        value: new Intl.NumberFormat('en-CA', {style: 'currency', currency: 'CAD'}).format(100000)
                    }
                ])
            })
        } catch(err) {
            console.log(err);
        }
    }, [areaName, sanitizedName])

   
    return (
        <div>
            <div>Name: {areaName}</div>
            {data.map(d => {
                return <p key={d.label}>{d.label}: {d.value}</p>
            })}
            <br />
        </div>)
}
export default Neighborhood;