
import React from 'react';
import { Provider } from "react-redux";
import { createStore, combineReducers } from "redux";
import Reducer from './store'
import Home from './pages/home'
import Header from './components/ui/header';
import Footer from './components/ui/footer'
import 'app.css'

export default function App() {
  const info = createStore(Reducer);
  return (
    <Provider store={info}>
      <Header></Header>
      <div className='body'>
        <div className='main-container'><Home/></div>
        <Footer></Footer>
      </div>
    </Provider>
  );
}