import React from 'react';
import title from '../../assets/images/strokeTitle.png';
import './header.css';
import { useSelector, useDispatch } from "react-redux";
import i18n from '../../i18n';
import { withNamespaces } from 'react-i18next';
import cn from '../../assets/images/cn.png'
import en from '../../assets/images/en.png'

function Header({t}) {
  const reducer = useSelector(state => state);
  const dispatch = useDispatch()
  const changeLanguage = () => {
    dispatch({type: 'toggleLang'});
    i18n.changeLanguage(reducer.language === 'cn' ? 'en' : 'cn');
  }
  return (
    <header>
      <img src={title} alt='title' className='headerTitle'></img>
      {/* <button className='btn'>Log in</button> */}
      <div className='btn' onClick={() => changeLanguage()}>
        <img src={cn} alt='cn'></img>
        {/* <p className={reducer.language === 'cn' ? 'active' : 'disabled'}>中文</p> */}
        <div className='disabled'>{ '\xa0' }/{ '\xa0' }</div>
        <img src={en} alt='en'></img>
        {/* <p className={reducer.language === 'en' ? 'active' : 'disabled'}>English</p> */}
      </div>
    </header>
  );
}

export default withNamespaces()(Header)