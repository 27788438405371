const initState = {
    loggedIn: false,
    authPopup: false,
    language: 'en',
}

const Reducer = (state = initState, action) => {
    switch (action.type) {
        case 'LOGIN':
            return {...state, loggedIn: true}
        case 'LOGOUT':
            return {...state, loggedIn: false}
        case 'toggleAuthPopup':
            return {...state, authPopup: !state.authPopup}
        case 'toggleLang':
            return {...state, language: state.language === 'cn' ? 'en' : 'cn'}
        default:
            return state
    }
}


export default Reducer;