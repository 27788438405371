import IHoverInfo from 'types/hoverinfo';

export function validateHoverInfo(hoverInfo: IHoverInfo): boolean {
    return hoverInfo.feature.properties!.AREA_NAME && hoverInfo.feature.properties!.AREA_ID;
}

export function distanceBetweenPoints(p1:Array<number>, p2: Array<number>): number {
    return Math.sqrt(Math.pow((p1[0] - p2[0]),2) + Math.pow((p1[1] - p2[1]),2))
}

export function sanitizeAreaName(name:string): string {
    if (name === undefined || name === null || typeof(name) !== 'string' ) {
        throw new TypeError(`Expected type string but instead got ${typeof(name)}`)
    }
    const bIdx = name.indexOf('(');
    if(bIdx === -1) {
        return name;
    }
    return name.replace(/ \(\d+\)$/g,"");;
}

export function calculatePercent(minority: number, majority: number): number {
    const totalPop = minority+majority;
    return (majority / totalPop);
}