import React from 'react';
import Map from '../components/Map';
import Neighborhood from '../components/Neighborhood';
import HumanStructure from '../components/HumanStructure';
import './home.css';
import Navigation from '../components/ui/navigation';
import mountain from '../assets/images/mountain.png'
import bird from '../assets/images/bird.png'
import forest from '../assets/images/forest.png'
import boat from '../assets/images/boat.png'
import demographic from '../assets/images/demographic.png'
import highlight from '../assets/images/highlight.png'
import map from '../assets/images/map.png'


function Home() {
  const [clickedFeature, setClickedFeature] = React.useState(null);
  return (
    <div>

      <div className='topBar'>
        <Navigation></Navigation>
      </div>

        <div className='container'>
        <div className='pattern'>
          <img alt='mountain' src={mountain} className='mountain'></img>
        </div>
          <div className='contents'>
            <div className={["neighbourContainer", "highlight"].join(' ')}>
              <img className='titleImg' src={highlight} alt={highlight}></img>
              <Neighborhood feature={clickedFeature}/>
            </div>

            <div className={["neighbourContainer", "map"].join(' ')}>
              {/* <div className="mapFrame"></div> */}
              <img className='titleImg' src={map} alt={map}></img>
              <div className="mapContainer">
                <Map setClicked={setClickedFeature}/>
              </div>
            </div>

            <div className={["neighbourContainer", "demographic"].join(' ')}> 
              <img className='titleImg' src={demographic} alt={demographic}></img>
              <HumanStructure feature={clickedFeature}/>
            </div>

            
          </div>
          
          <div className='pattern3'>
            <img alt='forest' src={forest} className='forest'></img>
          </div>
        </div>

      
      <img alt='bird' src={bird} className='bird'></img>
      
      {/* <img alt='boat' src={boat} className='boat'></img> */}
    </div>
  );
}

export default Home;
