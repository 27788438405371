import React, {useState} from 'react';
import './navigation.css'
import intro2 from '../../assets/images/intro2.png';
import home2 from '../../assets/images/home2.png';
import buy2 from '../../assets/images/buy2.png';
import sell2 from '../../assets/images/sell2.png';
import posts2 from '../../assets/images/posts2.png';
import contact2 from '../../assets/images/contact2.png';
import intro from '../../assets/images/Intro.png';
import home from '../../assets/images/Home.png';
import buy from '../../assets/images/Buy.png';
import sell from '../../assets/images/Sell.png';
import posts from '../../assets/images/Posts.png';
import contact from '../../assets/images/Contact_.png';
import { useSelector } from "react-redux";

export default function Navigation() {
    const reducer = useSelector(state => state);

    return (
        <div className='nav'>
            <div>
                {reducer.language === 'cn' ?
                <img alt='intro' src={intro2} className={['tab', 'lltab'].join(' ')}></img> :
                <img alt='intro' src={intro} className={['tab', 'lltab'].join(' ')}></img>}
            </div>
           
            <div>
                {reducer.language === 'cn' ?
                <img alt='home' src={home2} className={['tab', 'ltab'].join(' ')}></img> :
                <img alt='home' src={home} className={['tab', 'ltab'].join(' ')}></img>}
            </div>
           
            
            <div className='circle'>
                <div href='./buy'>
                    {reducer.language === 'cn' ?
                    <img alt='buy' src={buy2} className='buy'></img> :
                    <img alt='buy' src={buy} className='buy'></img>}
                </div>
                <div className='slash'></div>
                <div href='./sell'>
                    {reducer.language === 'cn' ?
                    <img alt='sell' src={sell2} className='sell'></img> :
                    <img alt='sell' src={sell} className='sell'></img>}
                </div>
            </div>
            
            
            <div>
                {reducer.language === 'cn' ?
                <img alt='posts' src={posts2} className={['tab', 'rtab'].join(' ')}></img>:
                <img alt='posts' src={posts} className={['tab', 'rtab'].join(' ')}></img>}
            </div>
            
            
            <div>
                {reducer.language === 'cn' ?
                <img alt='contact' src={contact2} className={['tab', 'rrtab'].join(' ')}></img> :
                <img alt='contact' src={contact} className={['tab', 'rrtab'].join(' ')}></img>}
            </div>
            
        </div>
    )
    
}