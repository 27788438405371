import React from 'react';
import title from '../../assets/images/strokeTitle.png';
import './footer.css';
import { useSelector } from "react-redux";
import { withNamespaces } from 'react-i18next';

function Footer({t}) {
  const reducer = useSelector(state => state);
  return (
    <div className='footer'>
        <p className='copyright'>{t('copyright')}</p>
    </div>
  );
}

export default withNamespaces()(Footer)